<template>
    <div class="ms_top_artist">
        <div class="container-fluid">
            <div class="row row--grid">
                <div class="col-12">
                    <div class="ms_heading">
                        <h1>التصنيفات</h1>
                    </div>
                </div>
                <div class="col-6 col-sm-6 col-lg-4 col-xl-4" v-for="(cat, index) in categorie" :key="index">
                    <div class="ms_rcnt_box marger_bottom30 pointer" style="margin-bottom: 40px;" @click="getCategorie(cat)">
                        <div class="ms_rcnt_box_img margin-zero" style="max-height: 10vh;">
                            <img v-lazy="cat.img" alt="" class="img-fluid">
                            <div class="ms_main_overlay">
                                <div class="ms_box_overlay"></div>
                            </div>
                        </div>
                        <div class="ms_rcnt_box_text card_top_margin" style="z-index:999;display:block;position:relative;">
                            <h3><a class="text_size" style="color: rgb(255, 255, 255); font-weight: 600;font-size: 15px;" @click="getCategorie(cat)">{{ cat.name }}</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router"
export default {
   name: 'AppCategorie',
   async setup() {
      const router = useRouter();
      const route = useRoute();
      const categorie = ref([]);

      try {
        await HTTP.get('GetAllCategories.php').then((res) => {
            categorie.value = res.data.Categories; 
        });
      } catch (err) {
        console.log(err);
      }
      const getCategorie = (cat) => {
          router.push({ name: "Categories", params: { cat_id: cat.id, cat_name: cat.name } });
      };
      return { categorie,getCategorie };
   }
}
</script>

<style>

</style>